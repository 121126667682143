<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <h1>Edit Website General Setting</h1>
      <b-row align-content="center" v-if="settingDetails" class="my-3">
        <b-col cols="12">
          <b-form @submit.prevent>
            <b-form-group label="Website Phone Number" label-for="phoneNumber">
              <b-form-input
                dir="auto"
                v-model="settingDetails.phoneNumber"
                placeholder="Enter Website Phone Number"
                id="phoneNumber"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" class="my-3">
          <b-form @submit.prevent>
            <b-form-group
              label="Primary Background color"
              label-for="primaryColor"
            >
              <b-form-input
                id="primaryColor"
                type="color"
                v-model="settingDetails.backgroundColorPrimary"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="12" class="my-3">
          <b-form @submit.prevent>
            <b-form-group
              label="Secondary Background Color"
              label-for="secondaryColor"
            >
              <b-form-input
                id="secondaryColor"
                type="color"
                v-model="settingDetails.backgroundColorSecond"
              ></b-form-input>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" @click="updateSetting"> Update </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "General Settings",
  mounted() {
    this.getSetting();
  },
  data() {
    return {
      isLoading: false,
      settingDetails: null,
    };
  },
  methods: {
    async getSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1 });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.settingDetails = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASetting = new UpdateASetting(_this);
        updateASetting.setRequestParamDataObj(_this.settingDetails);
        await updateASetting.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Succesful",
                icon: "CheckIcon",
                variant: "success",
                text: "General Settings Updated Successfully",
              },
            });
            _this.getSetting();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetASingleSetting, UpdateASetting } from "@/libs/Api/Setting";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
</script>
